<template>
      <div class="Anchor">
        <div class="flex anchor">
          <div
            v-for="(item, idx) in anchorList"
            :key="idx"
            @click="son(item, idx)"
          >
            <a class="flex" >
              <div class="mg-r14"><img class="itemImg" :src="item.icon" /></div>
              <div class="pd-t10">
                <h2>{{ item.introduce }}</h2>
                <p>{{item.appName}}</p>
                <!-- <p>{{ item.introduce }}</p>  -->
              </div>
            </a>
          </div>
        </div>
        <div>
        </div>
      </div>
</template>

<script>
import { getAnchorList } from '../../../api/home1'
export default {
  name:'functions',
  data(){
    return{
    anchorList:'',
    }
  },
  methods:{
    getanchorList () {
      getAnchorList({
        size: 5
      }).then((res) => {
        this.anchorList = res.data.records
        this.anchorList.sort(function(a,b){
          return a.sort - b.sort
        })
      })
    },
    son(item,idx){
        if(window.location.href.indexOf("?CAS_VALIDATION")!=-1){
           window.open(item.path+'?CAS_VALIDATION');
        }else{
          window.open(item.path);
        }

        //  this.$router.push('')
        // if(idx==3){
        // let routeData=this.$router.resolve({path:'/AgriculturalData'})
        //  window.open(routeData.href, '_blank');
        // }else{
          //window.open(item.path+'?CAS_VALIDATION');
          // location.href=item.path
        // }
    }
  },
  created () {
    this.getanchorList()
  }
}
</script>
<style scoped>
.Ancho{
  width: 1200px  !important;
  margin: 0 9%;
}
.anchor {
  justify-content: space-around;
  margin-bottom: 21px;
  padding: 0 24px;
}
.anchor div{
  cursor: pointer;
}
.itemImg{
  width: 53px;
  height: 53px;
}
h2{
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 4px;
  text-align: left;
}
a{
  text-align: center;
}
p{
  font-size: 16px;
}
.mg-r14{
  margin-right: 14px;
}
.pd-t10{
  padding-top: 10px;
}
</style>
