<template>
  <div class="banner">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in Img" :key="index" class="swiper-slide">
          <img :src="item.value">
        </div>
    </div>
    <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import { getBanner } from '@/api/home1'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import 'swiper/js/swiper.js'
export default {
  data () {
    return {
      Img: ''
    }
  },
  methods: {
    getbanner () {
      getBanner({}).then((res) => {
        this.Img = res.data.pathList
      })
    }
  },
  mounted () {
    this.getbanner()
    var swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 30,
      observer: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      autoplay: {
        delay: 2000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      }
    })
  }
}
</script>

<style scoped>
.banner{
  position: relative;
  height: 100%;
  width: 100%;
  background: #fff;
  font-size: 14px;
  color:#000;
  margin: 0;
  padding: 0;
  margin-bottom: 11px;
}
img{
  width: 100%;
  height: 109px;
}
</style>
