<template>
  <div class="h-panel cf">
    <!-- <div class="left-container">
      <div>
        <img src="@/assets/img/Policy.png" class="img-size" alt="">
      </div>
      <div class="h-title">
        <span class="title"><i class="icon icon_zhengce"></i>{{title}}</span>
        <span class="more fr" @click="son()">更多</span>
      </div>
      <div class="h-content">
        <div class="contnet-left">
          <div class="video">
            <img src="@/assets/img/video.png" alt="" @click="showModal=true">
          </div>
          <ul class="roll-content">
            <li v-for="item in leftfoot" :key="item.id" @click="skip(item)"><span>{{item.title}}</span></li>
          </ul>
        </div>
        <ul class="contnet-right">
          <li v-for="item in contentRightList" :key="item.id" @click="skip(item)">
            <h2>{{item.title }}</h2>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="right-container">
      <div class="h-tabs">
        <div class="h-tabs-head">
          <p class="tab-btn icon_tab01" :class="tableIndex === 0 ? 'active':''" @click="toggleTab(0)"><span>{{ constCommon.shortName}}新闻</span></p>
          <p class="tab-btn icon_tab01" :class="tableIndex === 1 ? 'active':''" @click="toggleTab(1)"><span>更多推荐</span></p>
        </div>
        <div class="h-tabs-body">
          <ul class="alllist" v-show="tableIndex == 0">
            <li class="body-li" v-for="item in EmergdationList" :key="item.id" @click="skip(item)">
              <ul>
                <li class="item-list">{{item.title}}</li>
                <p>{{item.content | escapeHTML}}</p>
              </ul>
            </li>
          </ul>
            <ul class="alllist atcive2" v-show="tableIndex == 1">
            <li class="body-li" v-for="item in moreErgadtionList" :key="item.id" @click="skip(item)">
              <ul>
                <li class="item-list">{{item.title}}</li>
                <p>{{item.content | escapeHTML}}</p>
              </ul>
            </li>
            <li class="body-li" v-show="moreErgadtionList.length == 0">
              <ul>
                <li class="item-list noData">暂无数据</li>
              </ul>
            </li>
          </ul>
          <div class="h-tabs-foot" v-show="EmergdationFootList.length && tableIndex == 0" v-for="item in EmergdationFootList" :key="item.id" @click="skip(item)">
            <div class="font-img" v-if="item.isShow && imgOK(item.icon)"><img :src="item.icon" alt=""></div>
            <div class="font-img" v-if="!item.isShow"><img src='../../../assets/img/imgEro.jpg' alt=""></div>
            <h3>{{item.title}}</h3>
          </div>
        </div>
      </div>
    </div><!--.right-container-->


    <div class="pop" v-if="showModal">
      <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                name="videoPlayer"
                id="video"
                :playsinline="true"
                :globalOptions="globalSetting"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ended="onPlayerEnded($event)"
                @waiting="onPlayerWaiting($event)"
                @timeupdate="onPlayerTimeupdate($event)"
                @statechanged="playerStateChanged($event)"
                @canplaythrough="onPlayerCanplaythrough($event)"
                @ready = "playerReadied"
        ></video-player>
    </div>
    <div class="mask" v-if="showModal" @click="showModal=false"></div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { getContentList, getRuralFinanceList, getPolicyContent, getInforId } from '@/api/home1'
  import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import {constCommon} from '@/consts/common'
  export default {
    name: 'policy',
    components:{
      videoPlayer
    },
    data () {
      return {
         constCommon:constCommon,
        title:'农业政策',
        contentRightList:[],
        showModal: false,
        informationId:'',
        policyId:'',
        emergdationId:'',
        leftfoot:[],
        tableIndex:0,
        columnName:'',
        EmergdationFootList:[],
        EmergdationList:[],
        moreErgadtionList:[],
        playTime:'',
        labelId:'',
        current:'',
        allId:'',
        columnNameId:'',
        playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: true, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: "zh-CN",
                    aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [
                        {
                            type: "video/mp4", // 类型
                            src:
                                "https://cz-video-view.oss-cn-beijing.aliyuncs.com/20200529/0cd7127dea9d8cd3b454c306503de3cc.mp4" // url地址
                        }
                    ],
                    poster: "", // 封面地址
                    notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    },
                },
      }
    },
            beforeRouteLeave(){
            this.$refs.videoPlayer.player.pause()
        },
    computed: {
      ...mapGetters({
        columnData: 'getColumnData'
      })
    },
    methods: {
      imgOK(img){
        if (img.indexOf("404.jpg") > 0){
          return false;
        }
        return true;
      },
      compart(obj1,obj2){
        let val1 = obj1.hits
        let val2 = obj2.hits
        if(val1 < val2){
          return 1
        }else if (val1 > val2) {
          return -1
        }else{
          return 0
        }
      },
      getleftfoot(){
        getRuralFinanceList({columnId:this.policyId}).then(res=>{
            res.data.forEach(element => {
              if (element.labelName == '政策') {
                this.labelId=element.id
                this.columnName = element.labelName
              }
            });
            getPolicyContent({labelId:this.labelId,size:14}).then(item=>{
            this.leftfoot=item.data.records.sort(this.compart)
            this.contentRightList=item.data.records
          })
        })
      },
      getAllId(){
      getInforId({parentId:this.columnData.information.id}).then((result) => {
        this.allId = result.data
        this.getItemList()
      });
    },
      getItemList(){
        this.allId.forEach(res=>{
          if (res.id == '1390862482014646273') {
            this.columnNameId=res.id
            getContentList({columnId:res.id}).then(res=>{
              this.EmergdationList = res.data.records.slice(0,3)
              this.EmergdationFootList = res.data.records.slice(3,4)
              this.EmergdationFootList.forEach(item=>{
                if (item.icon == '' || item.icon == null || item.icon == undefined) {
                  item.isShow=false
                }else{
                  item.isShow=true
                }
                })
              this.moreErgadtionList= res.data.records.slice(4)
        })
          }
        })
    },
      toggleTab(index){
        this.tableIndex =index
      },
      skip(item){
        this.title='农业资讯'
        this.columnName='新兴'
        let routerJump = this.$router.resolve({
          path: "/domesticDetails",
          query: {
            titleId: item.id,
            columnNameId:this.columnNameId,
            columid:this.columnData.information.id
          }
        })
        window.open(routerJump.href, '_blank');
      },
      son() {
        this.$router.push({
          path:'/BenefitfarmersPolicy',
          query:{
            labelId:this.labelId
          }
        })
      },
            // 播放回调
            onPlayerPlay(player) {
                console.log("player play!", player);
            },

            // 暂停回调
            onPlayerPause(player) {
                console.log("player pause!", player);
            },

            // 视频播完回调
            onPlayerEnded(player) {
                console.log(player);
            },
            // DOM元素上的readyState更改导致播放停止
            onPlayerWaiting(player) {
                let time = localStorage.getItem("cacheTime")
                if(player.cache_.currentTime - Number(time) > 0.1){
                    this.current = Number(time)
                    this.playerReadied(player)
                }else{
                    this.current = player.cache_.currentTime
                }
            },

            // 已开始播放回调
            onPlayerPlaying($event) {
                // console.log(player)
            },

            // 当播放器在当前播放位置下载数据时触发
            onPlayerLoadeddata($event) {
                // console.log(player)
            },

            // // 当前播放位置发生变化时触发。
            onPlayerTimeupdate(player) {
                this.playTime = player.cache_.currentTime
                let playTime = player.cache_.currentTime
                setTimeout(function () {
                    localStorage.setItem("cacheTime",playTime)
                },500)


                let time = localStorage.getItem("cacheTime")
                if(player.cache_.currentTime - Number(time) > 2){
                    this.current = Number(time)
                    this.playerReadied(player)
                }else{
                    this.current = player.cache_.currentTime
                }
            },

            //媒体的readyState为HAVE_FUTURE_DATA或更高
            onPlayerCanplay(player) {
                // console.log('player Canplay!', player)
            },

            //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
            onPlayerCanplaythrough(player) {
                // console.log('player Canplaythrough!', player)
            },

            //播放状态改变回调
            playerStateChanged(playerCurrentState) {
                // console.log('player current update state', playerCurrentState)
            },

            //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
            playerReadied(player) {
                // console.log('example player 1 readied', player);
                player.currentTime(this.current)
            }
    },
    mounted () {
    },
    watch: {
      columnData (val) {
        if (JSON.stringify(val) !== '{}') {
          this.informationId = this.columnData.information.id
          this.policyId = this.columnData.BenefitfarmersPolicy.id
          this.emergdationId = this.columnData.emergdation.id
          // this.getleftfoot()
          this.getAllId()
        }
      }
    },
    filters:{
      escapeHTML(untrusted) {
      return untrusted
        .replace(/<[^>]*>/g)
        .replace(/undefined/g, '')
        .replace(/&nbsp;/g, '')
     }
    }
  }


</script>

<style scoped lang="scss">
  .icon_zhengce {
    background-image: url("~@/assets/img/icon_zhengce.png");
  }
  .tab-btn.icon_tab01 span:before {
    background-image: url("~@/assets/img/icon_tuijian.png");
  }
  .h-title{
    margin-bottom: 21px;
  }
  .img-size{
    width: 100%;
    height: 122.5px;
  }
  .h-content{
    display: flex;
    justify-content: space-between;
  }
  .contnet-left{
    width: 33%;
  }
  .video{
    border: 1px solid #c4c4c4;
  }
  li:hover, .h-tabs-foot h3:hover {
    color: #51d5c3 !important;
  }
  .h-tabs-foot h3{
    cursor: pointer;
    overflow:hidden;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    white-space:nowrap;
  }
  .body-li:hover{
    color: #51d5c3 !important;
  }
  .roll-content {
    height: 119px;
    margin-top:17px ;
    overflow: auto;
  }
  .roll-content li{
    border-bottom: 1px solid #c4c4c4;
    font-size: 12px ;
    color: #494949;
    padding-bottom: 7px;
    line-height: 19px;
    cursor: pointer;
  }
  .contnet-right{
    height: 463px;
    overflow: auto;
    width: 63%;

  }
  .contnet-right li{
    margin-bottom: 7px;
    letter-spacing:1px;
    cursor: pointer;
  }
  .contnet-right li:last-child{
    margin-bottom: 0px;
    }
  .contnet-right span{
    overflow:hidden;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    white-space:nowrap;
  }
  .contnet-right li p{
    font-size: 12px;
    overflow:hidden;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    white-space:nowrap;
    height: 15px;
    color: #999898 !important;
  }
  .contnet-right li h2{
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 26px;
  }
  .h-tabs-body{
    padding: 15px 20px;
  }
  p{
    font-size: 12px;
  }
  .body-li{
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 10px;
    padding-bottom: 6px;
    cursor: pointer;

  }
  .body-li p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
  color: #999898;
  }
  .item-list{
    list-style: disc;
    margin-bottom: 5px;
    font-size: 16px;
    overflow:hidden;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    white-space:nowrap;
    line-height: 31px;
  }
  .font-img{
    width: 100%;
    height: 130px;
  }
  .font-img img{
    width: 100%;
    height: 100%;
    cursor: pointer !important
  }
  .h-tabs-foot h3{
    font-size: 16px;
    line-height: 40px;
  }
  .alllist{
    height: 280px;
    margin-bottom: 14px;
    overflow: auto;
  }
  .body-li p{
    font-size: 14px;
    line-height: 20px;
  }
  body li:hover .item-list,body li:hover p{
    color: #51d5c3 !important;
  }
  .pop {
  background-color: #fff;
  position: fixed;
  top: 150px;
  left: 300px;
  width: calc(100% - 600px);
  z-index: 2
}
.btn {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid blue;
  padding: 4px 12px;
}
.mask {
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.atcive2{
  height: 554px !important;
}
.noData{
  color: rgb(136, 134, 134);
  text-align: center !important;
  width: 100% !important;
}

.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0,0,0,0.45);
  font-size: 3.0em;
  /* border-radius: 50%; */
  height: 1.5em !important;
  line-height:1.5em !important;
  margin-top: -1em !important;
    }
    /*这里用了第三方vue-video-player插件，但这个插件有bug，设置globalSetting:{controls:true}隐藏进度条不生效，故可设置插件样式进行隐藏vjs-progress-contro*/
  .vjs-progress-control {
        /*visibility:hidden;       // 隐藏进度条*/
    }

</style>
