<template>
  <div class="Anchor">
    <ul class="flex">
      <li
        v-for="(item, idx) in navList"
        :key="idx"
        @click="changeNav(idx + 1, item)"
        v-show="item.isHome == 0"
      >
        <span class="a_item">{{ item.columnName }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getNavigationList } from "@/api/home1";
export default {
  data() {
    return {
      navList: [],
      columnData: {
        information: {}, // 农业资讯
        ruralFinance: {}, // 农村金融
        BenefitfarmersPolicy: {}, // 惠农政策
        emergdation: {} // 新兴推荐
      }
    };
  },
  methods: {
    // 获取导航信息
    getList() {
      getNavigationList({
        parentId: 0
      }).then(res => {
        this.navList = res.data.records;
        let columnData = [];
        this.navList.forEach((item, index) => {
          // 摘取缓存的字段
          const temp = {
            id: item.id,
            accessPath: item.accessPath,
            columnName: item.columnName,
            customPath: item.customPath
          };
          columnData.push(temp);
        });
        // 栏目数据以key为标识
        const that = this;
        columnData.forEach(item => {
          if (item.id === "1366599590035247105") {
            // 农业资讯
            that.columnData.information = item;
          }
          if (item.id === "1366631984498921473") {
            // 农村金融
            that.columnData.ruralFinance = item;
          }
          if (item.id === "1386253847422713857") {
            // 惠农政策
            that.columnData.BenefitfarmersPolicy = item;
          }
          if (item.id === "1392292803893440513") {
            // 新兴推荐
            that.columnData.emergdation = item;
          }
        });
        this.$store.commit("setColumnData", that.columnData);
        // 缓存数据，防止刷新数据丢失
        window.sessionStorage.setItem(
          "_digitalagri_comprehensive_column_",
          JSON.stringify(that.columnData)
        );
      });
    },

    // 切换导航
    changeNav(index, item) {
      if (item.isNew === "1") {
        if (item.id === "1393021449385963522") {
          // 质量追溯
          this.$router.push({
            path: "/agricuunforThree",
            query: {
              columid: item.id,
              columnNameId: item.id
            }
          });
        } else if (item.columnName === "猪价行情") {
          this.$router.push({
            path: item.accessPath,
            query: {
              columid: item.id,
              columnNameId: item.id
            }
          });
        } else {
          this.$router.push({
            path: item.accessPath,
            query: {
              columid: item.id,
              columnNameId: item.id
            }
          });
        }
      } else {
        // 1451077551417073665
        this.$router.push({
          path: "/commonLayout",
          query: {
            columid: item.id,
            columnNameId: item.id,
            level: item.level,
            columnName: item.columnName,
            parentId: item.parentId
          }
        });
      }
    }
  },
  mounted() {
    this.getList();
  },
  created() {},
  destroyed() {}
};
</script>

<style scoped>
.Anchor {
  width: 100% !important;
  border-top: 7px solid #51d5c3;
  border-bottom: 2px solid #51d5c3;
}
.Anchor ul {
  justify-content: space-around;
  font-size: 18px;
  font-weight: bolder;
  line-height: 48px;
}
.Anchor ul li {
  cursor: pointer;
}
li:hover {
  color: #51d5c3 !important;
}
</style>
