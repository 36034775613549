<template>
  <div>
     <informations></informations>
     <market></market>
     <policy></policy>
     <business></business>
  </div>  
</template>

<script>
import Business from './businessright.vue'
import informations from './informationright.vue'
import market from './marketright.vue'
import Policy from './policyright.vue'
export default {
  name: 'right',
  components: { 
    informations,
    market,
    Policy,
    Business
  },

}
</script>

<style>

</style>