<template>
  <div class="h-panel cf">
    <!-- <div class="left-container">
      <div class="h-title">
        <span class="title"><i class="icon icon_hangqing"></i>市场行情</span>
      </div>
      <div class="left">
        <div class="leftTop">
          <div class="flex marketList">
            <div class="shengzhu flex">
              <div>
                FT
              </div>
              <div>
                <p>生猪</p>
                <p class="color999">(外三元)</p>
              </div>
            </div>
            <div id="myChart" :style="{ width: '50%', height: '60px' }"></div>
            <div class="marketListBottom">
              <div>+0.4/公斤</div>
              <p class="color999 tright">.14.99</p>
            </div>
          </div>
           <div class="flex marketList">
            <div class="shengzhu flex">
              <div>
                FT
              </div>
              <div>
                <p>生猪</p>
                <p class="color999">(内三元)</p>
              </div>
            </div>
            <div id="myChart1" :style="{ width: '50%', height: '60px' }"></div>
            <div class="marketListBottom">
              <div>+0.4/公斤</div>
              <p class="color999 tright">14.99</p>
            </div>
          </div>
           <div class="flex marketList">
            <div class="shengzhu flex">
              <div>
                FT
              </div>
              <div>
                <p>生猪</p>
                <p class="color999">(土杂猪)</p>
              </div>
            </div>
            <div id="myChart2" :style="{ width: '50%', height: '60px' }"></div>
            <div class="marketListBottom">
              <div>+0.4/公斤</div>
              <p class="color999 tright">14.99</p>
            </div>
          </div>
          <div class="flex marketList">
            <div class="shengzhu flex">
              <div>
                FT
              </div>
              <div>
                <p>生猪</p>
                <p class="color999">(长白猪)</p>
              </div>
            </div>
            <div id="myChart3" :style="{ width: '50%', height: '60px' }"></div>
            <div class="marketListBottom">
              <div>+0.4/公斤</div>
              <p class="color999 tright">14.99</p>
            </div>
          </div>
          <div class="flex marketList">
            <div class="shengzhu flex">
              <div>
                FT
              </div>
              <div>
                <p>生猪</p>
                <p class="color999">(元猪)</p>
              </div>
            </div>
            <div id="myChart4" :style="{ width: '50%', height: '60px' }"></div>
            <div class="marketListBottom">
              <div>+0.5/公斤</div>
              <p class="color999 tright">19.31</p>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <ul>
          <li v-for="(item,idx) in pigpricoHot" :key="idx" @click="son(item)">
            <a href="javasript:;">[猪价]</a>{{item.title}}
          </li>
        </ul>
      </div>
    </div> -->
    <div class="right-container">
      <h1 class="title"><i class="icon"></i>{{ constCommon.shortName}}猪价</h1>
      <div class="rightTitle">
        <span>时间</span>
        <span>价格</span>
        <span>涨跌幅</span>
      </div>
      <div class="rightContent"
           v-for="(item,index) in dataList"
           :key="index">
        <span>{{item.time.slice(0,11)}}</span>
        <span>{{item.price}}</span>
        <span :class="item.growthRate*1<0?'green':'red'" :style="item.growthRate*1<0?'':'margin-left:10px'" v-if="item.growthRate*1!=0">{{(((item.growthRate)*100).toFixed(2))}}%</span>
        <span v-if="item.growthRate*1==0" style="margin-left:10px">一&nbsp;一</span>
      </div>
      <!-- <ul>
        <li v-for="(item,idx) in pigpricoList.slice(0,8)" :key="idx" @click="son(item)">
          <span :class="idx<3?'active':''">{{idx+1}}</span>{{item.title}}
        </li>
      </ul> -->
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {
    getQuotes,
    getQuotesList,
    getdetail,
    getxinxingPrice
  } from '@/api/home2.js'
  import echarts from 'echarts'
  import {constCommon} from '@/consts/common'

  export default {
    name: 'market',
    data() {
      return {
        address: constCommon.shortName,
        constCommon: constCommon,
        pigpricoList: [],
        pigpricoHot: [],
        dataList: []
      }
    },
    methods: {
      drawLine() {
        var chartDom = document.getElementById('myChart')
        var myChart = echarts.init(chartDom)
        var option

        option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            show: false
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false //不显示网格线
            },
            axisLabel: {
              show: false //不显示坐标轴上的文字
            },
            axisLine: {
              show: false //不显示坐标轴线
            },
            axisTick: {
              show: false //不显示坐标轴刻度线
            }
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              itemStyle: {
                color: '#ec5751'
              },
              areaStyle: {
                color: '#fae2e3'
              },
              symbol: 'none'
            }
          ]
        }
        option && myChart.setOption(option)
      },
      drawLine1() {
        var chartDom = document.getElementById('myChart1')
        var myChart = echarts.init(chartDom)
        var option

        option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            show: false
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false //不显示网格线
            },
            axisLabel: {
              show: false //不显示坐标轴上的文字
            },
            axisLine: {
              show: false //不显示坐标轴线
            },
            axisTick: {
              show: false //不显示坐标轴刻度线
            }
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              itemStyle: {
                color: '#ec5751'
              },
              areaStyle: {
                color: '#fae2e3'
              },
              symbol: 'none'
            }
          ]
        }
        option && myChart.setOption(option)
      },
      drawLine2() {
        var chartDom = document.getElementById('myChart2')
        var myChart = echarts.init(chartDom)
        var option

        option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            show: false
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false //不显示网格线
            },
            axisLabel: {
              show: false //不显示坐标轴上的文字
            },
            axisLine: {
              show: false //不显示坐标轴线
            },
            axisTick: {
              show: false //不显示坐标轴刻度线
            }
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              itemStyle: {
                color: '#ec5751'
              },
              areaStyle: {
                color: '#fae2e3'
              },
              symbol: 'none'
            }
          ]
        }
        option && myChart.setOption(option)
      },
      drawLine3() {
        var chartDom = document.getElementById('myChart3')
        var myChart = echarts.init(chartDom)
        var option

        option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            show: false
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false //不显示网格线
            },
            axisLabel: {
              show: false //不显示坐标轴上的文字
            },
            axisLine: {
              show: false //不显示坐标轴线
            },
            axisTick: {
              show: false //不显示坐标轴刻度线
            }
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              itemStyle: {
                color: '#ec5751'
              },
              areaStyle: {
                color: '#fae2e3'
              },
              symbol: 'none'
            }
          ]
        }
        option && myChart.setOption(option)
      },
      drawLine4() {
        var chartDom = document.getElementById('myChart4')
        var myChart = echarts.init(chartDom)
        var option

        option = {
          xAxis: {
            type: 'category',
            boundaryGap: false,
            show: false
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false //不显示网格线
            },
            axisLabel: {
              show: false //不显示坐标轴上的文字
            },
            axisLine: {
              show: false //不显示坐标轴线
            },
            axisTick: {
              show: false //不显示坐标轴刻度线
            }
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              itemStyle: {
                color: '#ec5751'
              },
              areaStyle: {
                color: '#fae2e3'
              },
              symbol: 'none'
            }
          ]
        }
        option && myChart.setOption(option)
      },
      getpricoList() {
        getdetail({
          id: '1386281981576626178'
        }).then((res) => {
          getQuotesList({
            columnId: res.data.id,
            isRecommend: 0
          }).then((res) => {
            this.pigpricoHot = res.data.records
          })
          getQuotesList({
            columnId: res.data.id
          }).then((res) => {
            this.pigpricoList = res.data.records
          })
        })
      },
      son(item) {
        let routerJump = this.$router.resolve({
          path: '/domesticDetails',
          query: {
            titleId: item.id,
            columid: '1386281981576626178',
            columnNameId: '1386281981576626178'
          }
        })
        window.open(routerJump.href, '_blank')
      },
      getxinxingPrice2(address) {
        const parm = {
          address: address,
        };
        getxinxingPrice(parm).then((res) => {
          this.dataList = res.data
        })
      }
    },
    mounted() {
      // this.drawLine()
      // this.drawLine1()
      // this.drawLine2()
      // this.drawLine3()
      // this.drawLine4()
      this.getxinxingPrice2(this.address)
      // this.getpricoList()
    },
    computed: {
      ...mapGetters({
        columnData: 'getColumnData'
      })
    },
    watch: {
      columnData(val) {
        if (JSON.stringify(val) !== '{}') {
          const columId = this.columnData.information.id
        }
      },
      immediate: true
    }
  }
</script>

<style scoped>
  .icon_hangqing {
    background-image: url('~@/assets/img/icon_hangqing.png');
  }

  .aitems {
    align-items: center;
  }

  .left-container .h-title {
    margin-bottom: 30px;
  }

  .left-container .left {
    width: 45%;
    font-size: 14px;
  }

  .left-container .leftTop {
    width: 100%;
  }

  .left-container .leftTop > div {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .left-container .shengzhu div:nth-child(1) {
    background-color: #51d5c3;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
  }

  .color999 {
    color: #cacaca;
  }

  .left-container .marketListBottom div {
    color: #fff;
    background-color: #e74748;
    width: 80px;
    margin-top: 8px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 5px;
  }

  .left-container .marketListBottom p {
    margin-top: 5px;
  }

  .left-container .right {
    width: 50%;
    font-size: 16px;
  }

  .left-container .right li {
    position: relative;
    margin: 15px 0;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }

  .left-container .right li::before {
    content: '·';
    position: absolute;
    color: #c6c6c6;
    font-size: 30px;
    left: -10px;
    top: -10px;
  }

  .left-container .right li a {
    color: #c6c6c6;
    margin-right: 10px;
  }

  .right-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 20px 20px;
    border: 1px solid #dcdcdc;
  }

  .right-container h1 {
    font-weight: bold;
    font-size: 17px;
    margin: 0 0 15px;
    color: #7a7a7a;
    font-weight: bold;
    font-family: '黑体';
  }

  .right-container li {
    margin: 10px 0;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }

  .right-container li span {
    border: 1px solid #bababa;
    font-weight: bold;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-right: 20px;
    color: #626262;
  }

  .right-container .active {
    color: #fff;
    background-color: #51d5c3;
    border: none;
  }

  li:hover {
    color: #51d5c3;
  }

  .rightTitle {
    width: 320px;
    display: flex;
    color: #666;
    font-size: 16px;
    justify-content: space-around;
    background: #9ee4da;
    padding: 5px 0;
    font-size: 14px;
  }

  .right-container .title .icon {
    width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    background-size: 100% 100%;
    margin-right: 10px;
    background-image: url('~@/assets/img/icon_title_price.png');
  }

  .rightContent {
    margin: 0 0 0 10px;
    width: 320px;
    display: flex;
    height: 30px;
    color: #a5a3a3;
    line-height: 50px;
    font-size: 13px;
    color: #999;
  }

  .rightContent span:nth-child(1) {
    width: 125px;
  }

  .rightContent span:nth-child(2) {
    width: 100px;
  }

  .rightContent span:nth-child(3) {
    /* width: 100px; */
    margin-left: 5px;
  }

  .green {
    color: green;
  }

  .red {
    color: red;
  }
</style>
