<template>
  <div >
    <Top></Top>
    <div class="home-container">
      <Prico></Prico>
      <Functions></Functions>
      <Banner></Banner>
      <Navigation></Navigation>
      <div class="flex">
        <Left class="left"></Left>
        <Right class="right"></Right>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
  import Top from '../components/top'
  import Prico from '../components/prico.vue'
  import Functions from './components/functions'
  import Navigation from './components/navigation'
  import Banner from './components/banner'
  import Foot from '../components/footer'
  import Left from './components/left.vue'
  import Right from './components/right.vue'
  export default {
    name: 'home',
    components: {
      Top,
      Prico,
      Functions,
      Navigation,
      Banner,
      Foot,
      Left,
      Right
    },
        
  }
</script>

<style lang="scss">
@import "@/styles/home.scss";
.left{
  width: 70%;
  height: 100%;
}
.right{  
  width: 28%;
  height: 100%;
}
.flex{
  justify-content: space-between;
}
</style>
